.layout_sidebar_container {
  width: 120px !important;
}

.layout_section_end {
  position: absolute;
  bottom: 0;
  width: 100%;

  // left: calc(50% - 16px);
}

.layout_section_navItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.layout_sidenav_title {
  font-size: 10px;
  font-weight: 400;
}
