body {
  font-family: "Poppins", sans-serif !important;
}

.poppins_font {
  font-family: "Poppins", sans-serif;
}

.tab-content {
  padding: 1rem 0 !important ;
}

.profile_section {
  box-shadow: 0px 4px 25px 0px #00000014;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 772px;
}

.profile_section_message_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .profile_section_message {
    box-shadow: 0px 4px 25px 0px #00000014;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
  }

  .profile_section_message_title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .profile_section_message_desc {
    font-size: 14px;
    color: #8792a2;
    margin-bottom: 0;
  }

  .profile_section_message_label {
    font-size: 14px;
    margin-bottom: 4px;
    margin-top: 10px;
  }
}

.profile_section_title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.profile_section_desc {
  font-size: 14px;
  color: #8792a2;
  margin-bottom: 0;
}

.profile_section_label {
  font-size: 14px;
  margin-bottom: 4px;
  margin-top: 10px;
}

.profile_submit_btn {
  width: 122px;
  height: 40px;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 6px;
  margin-left: auto;
  margin-top: 10px;
}

.profile_cancel_btn {
  width: 122px;
  height: 40px;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 6px;
  margin-top: 10px;
}

.profile_icon_show {
  position: absolute;
  right: 10px;
  bottom: 15px;
}

.profile_input {
  background-color: #fff;
  border: 1px solid #dddce1;
  border-radius: 10px;
  height: 44px;
}

.profile_title {
  margin-bottom: 20px;
}

.profile_personalInfo {
  margin-bottom: 20px;

  .profile_personalInfo_section {
    box-shadow: 0px 4px 25px 0px #00000014;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.profile_img_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .profile_img_container_inner {
    width: 100%;
    max-width: 394px;
    flex-shrink: 0;
  }
}

.profile_img {
  width: 100%;
  max-width: 169px;
  height: 100%;
  max-height: 169px;
  object-fit: cover;
  border-radius: 4px;
  flex-shrink: 0;
}

.profile_input_social {
  padding-left: 60px;
  position: relative;
}

.profile_preview_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.profile_preview_header_title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.profile_preview_header_btn {
  width: 122px;
  height: 40px;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 6px;
  margin-left: auto;
  margin-top: 10px;
}

.profile_modal_img {
  width: 66px;
  height: 66px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}

.profile_modal_name {
  font-size: 16px;
  color: #5f3994;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  margin-top: 8px;
  margin-bottom: 0;
}

.profile_modal_role {
  font-size: 16px;
  color: #5f3994;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0;
}

.profile_modal_btn {
  width: 106px;
  height: 35px;
  background-color: #5f3994;
  color: #fff;
}

.profile_preview_header_close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.profile_preview_section {
  box-shadow: 0px 4px 25px 0px #00000014;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.profile_desktopPreview_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  margin-top: 8px;
}
