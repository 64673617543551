.payment-form {
  .StripeElement {
    background: #f8f9fa;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.02);
    border-radius: 20px;
    width: 100%;
    padding: 8px 15px;
    &--focus {
      border-color: #a679d2;
      background-color: #fff;
    }
  }

  .cvc-data-container {
    display: flex;
    .StripeElement:first-child {
      border-bottom-left-radius: 20px !important;
      border-top-left-radius: 20px !important;
      border-bottom: 1px solid #e3e3e3 !important;
      border-radius: 0px;
      width: 50%;
    }
    .StripeElement:last-child {
      border-bottom-right-radius: 20px !important;
      border-top-right-radius: 20px !important;
      border-radius: 0px;
      width: 50%;
    }
  }
}

.rw-popup {
  max-height: 107px;
  overflow: hidden;
  .rw-list {
    overflow: hidden;
  }
}

.lang_dropdown {
  background-color: #5f3994 !important;
  color: #fff !important;

  :hover {
    background-color: #5f3994 !important;
    color: #fff !important;
    text-decoration: none !important;
  }
}

.offer_header {
  background: #b2f5ea;
  font-family: "Poppins", sans-serif;
  width: 100vw;
  padding-top: 20px;
  padding-bottom: 20px;
  h1 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 0 !important;
  }
}

.choiceOffer_input {
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #dddce1;
  height: 44px;
}
