.box-image {
  min-height: 21.875rem;
}

.box-cover-image {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.hero-detail-box {
  position: absolute;
  z-index: 2;
  right: -51px;
  top: 50%;
  transform: translateY(-50%);
}

.badge-box {
  display: inline-block;
  background-color: #5f39940f;
  color: #5f3994;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 4px 10px 4px 10px;
  border-radius: 6px;
}

.steps-form {
  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #8792a2;
    background-color: #dddce1;
    border-color: #dddce1;

    &:hover {
      box-shadow: none;
    }
  }
}

.step-box-1 {
  background-color: #fff;
  border-radius: 1.25rem;
  height: 13.75rem;
  border: 2px solid transparent;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover,
  &.active {
    border-color: #5f3994;

    h6 {
      color: #5f3994;
    }
  }
}

.text-underline {
  text-decoration: underline;
}

.step-image-box {
  height: 18.75rem;
  width: 18.75rem;
}

.step-image-box-2 {
  height: 23.3125rem;
  width: 23.3125rem;
}

// .form-select {
//   display: block;
//   width: 100%;
//   height: calc(1.9695rem + 2px);
//   padding: 0.375rem 0.75rem;
//   font-size: 0.813rem;
//   font-weight: 400;
//   line-height: 1.5;
//   outline: initial !important;
//   background: #f8f9fa;
//   border: 1px solid #ced4da;
//   color: #47404f;
//   background-image: url("data:image/svg+xml,#{$svgData}");
//   background-repeat: no-repeat;
//   background-position: right 0.75rem center;
//   background-size: 16px 12px;
//   border-radius: 0.25rem;
//   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
// }

.box-result-prices {
  margin: 0;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.125rem;

  strong {
    display: block;
    white-space: nowrap;
    font-size: 1rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

.box-result-prices:nth-child(1) {
  border: 1px solid #ced4da;
  border-right: none;
  border-radius: 8px 0 0 8px;
}

.box-result-prices:nth-child(2) {
  border: none;
  border-bottom: 1px solid #5f3994;
  border-top: 1px solid #5f3994;
  background-color: #5f3994;
  color: #fff;
}

.box-result-prices:nth-child(3) {
  border: 1px solid #ced4da;
  border-left: none;
  border-radius: 0 8px 8px 0;
}

.step-box-1 {
  background-color: #fff;
  border-radius: 1.25rem;
  height: 13.75rem;
  border: 2px solid transparent;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover,
  &.active {
    border-color: #5f3994;

    h6 {
      color: #5f3994;
    }
  }
}
