.newlead_timer {
  background-color: #51555a;
  color: #fc9402;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin-top: -1rem;
  margin-bottom: 1rem;
  padding: 4px 16px;
}

.newlead_timer_time {
  font-size: 18px;
  font-weight: 600;
}

input:disabled {
  cursor: not-allowed;
  background-color: white;
}

.newleads_filter_sticky {
  position: fixed;
  top: 0;
  z-index: 100;
  // background-color: white;
  // padding: 8px 0;
  // border-bottom: 1px solid #e2e8f0;
}

.newleads_filter_summary {
  // border: 1px solid #e2e8f0;
}

.newleads_filter_summary_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 16px;
  // margin-bottom: 8px;
  // border-bottom: 1px solid #e2e8f0;
}

.newleads_filter_summary_img {
  width: 16px;
  height: 16px;
  cursor: pointer;
  // border: 1px solid #e2e8f0;
  // border-radius: 8px;
  // padding: 4px;
  // margin-right: 8px;
}

.newleads_filter_summary_municip {
  display: inline-block;
  padding: 2px 4px;
  border-radius: 6px;
  background-color: #e2e8f0;
  margin-right: 4px;
  margin-bottom: 10px;
}
