.badge-box-2 {
  padding: 0.25rem 0.625rem;
  border-radius: 0.375rem;
  background-color: #acc12f1a;
  color: #66731c;
  font-family: "Poppins", sans-serif !important;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0044rem;
}

.badge-box-3 {
  padding: 0.4399rem 0.7rem;
  border-radius: 0.4568rem;
  background-color: #8792a2;
  color: #fff;
  font-family: "Poppins", sans-serif !important;
  font-size: 0.8rem;
  font-weight: 500;
}

.map-box {
  max-width: 62rem;
  height: 20rem;
  border-radius: 0.5rem;
}
