/* .error {
  border: 2px solid red;
} */
.mySalesForm {
  width: 100%;
}
.mySalesBtnDiv {
  width: 80%;
  display: flex;
  justify-content: center;
}
.mySalesAddressInput {
  background-image: "url(/assets/icons/pin.png)";
  background-position: "left";
  padding-left: "30px";
  background-size: "15px";
  background-repeat: "no-repeat";
  font-size: "0.9rem";
  color: "#000";
  height: "2rem";
  width: 100%;
}
.mySalesForm input {
  outline: none;
}

.amountDiv {
  position: relative;
  border: 1px solid #000;
  display: flex;
  align-items: center;
}
.amountInput {
  border: none;
  text-align: left;
  padding-left: 10px;
  width: 20%;
}
.copropertyItems {
  padding-left: 10px !important;
}

/* .amountDiv:after {
  position: absolute;
  left: 6px;
  top: 2px;
  content: "$";
} */
@media (max-width: 767px) {
  .mySalesInputs {
    font-size: 0.7rem !important;
    padding: 0;
    padding-left: 5px;
  }
  .mySalesAddressInput {
    background-image: "url(/assets/icons/pin.png)";
    backgroundposition: "left";
    paddingleft: 3px;
    backgroundsize: 15px;
    backgroundrepeat: no-repeat;
    font-size: 0.7rem !important;
    color: #000;
    height: 2rem;
    width: 99%;
    padding: 3px;
  }
  .card-body {
    padding: 0;
  }
  .dateInputs {
    padding-left: 5px !important;
  }
  .amountInput {
    border: none;
    text-align: left;
    padding-left: 10px;
    width: 50%;
  }
  .dropDownBtn {
    font-size: 0.7rem !important;
  }
  .mySalesForm {
    width: 100%;
  }
  .mySalesForm input {
    font-size: 0.77rem !important;
    line-height: 1.6rem;
  }
  .dropdown {
    padding-left: 0 !important;
    font-size: 0.7rem !important;
  }
  .dropdown .dropdown-menu .copropertyItems {
    padding-left: 5px !important;
  }
  .mySalesDivContainer {
    overflow-x: scroll;
    position: relative;
  }

  .mySalesDivContainer::-webkit-scrollbar {
    position: absolute;
    top: 50%;
    width: 10px;
  }
  .mySalesContainer::-webkit-scrollbar {
    width: 10px;
  }

  .mySalesContainer::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  .mySalesContainer::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  .mySalesContainer::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  .mySalesForm {
    width: 500px;
    padding-left: 15px;
  }
  .custom-calendar {
    position: absolute;
    top: 15px;
    left: 30%;
    z-index: 9999;
  }
}

.profile_sales_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 10px;

  .profile_sales_header_title {
    font-size: 28px;
    font-weight: 600;
  }

  .profile_sales_header_btn {
    font-size: 14px;
    font-weight: 500;
    margin-left: 1rem;
  }
}

.profile_sales_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px;
  gap: 16px;
  margin-top: 32px;
  margin-bottom: 50px;
}

.profile_sales_sale_selected {
  border: 1px solid #5f3994;
  background-color: #fff !important;
}

.profile_sales_sale {
  border-radius: 20px;
  background-color: #f5f6f7;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 300px;
  height: 175px;
  padding: 20px 20px;

  .profile_sales_sale_data {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: auto;
  }

  .profile_sales_sale_adress {
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
  }

  .profile_sales_sale_chars {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
    font-weight: 500;
    color: #8792a2;
  }

  .profile_sales_sale_estimation {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    color: #8792a2;

    :last-child {
      background-color: #8792a2;
      color: #fff;
      border-radius: 8px;
      font-weight: 500;
      padding: 0.25rem 0.5rem;
    }
  }
}

.profile_sales_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-90w {
  height: 100% !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
}

.modal-dialog,
.modal-90w {
  width: 45vw !important;
  max-width: 45vw !important;
  min-width: 690px !important;

  @media (max-width: 767px) {
    width: 98% !important;
    min-width: auto !important;
    max-width: 98% !important;
  }
}

.modal-dialog {
  margin-bottom: 0 !important;
}

.modal-content-custom {
  height: 100% !important;
  width: 100% !important;
  border-radius: 0 !important;
  overflow-y: auto !important;
}

.profile_sales_preview {
}

.profile_sales_preview_title {
  font-size: 24px;
  font-weight: 600;
}

.profile_sales_preview_section {
  padding: 20px 10px;
  border: 1px dashed #e2e8f0;
  margin-bottom: 10px;
}

.profile_sales_preview_field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  :last-child {
    font-weight: 600;
  }
}

.profile_sales_delete_modal {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.profile_sale_add_field {
  width: 100%;
  margin-bottom: 10px;

  .react-datepicker-wrapper {
    width: 100% !important;
  }
}

.profile_sale_add_field_label {
  font-size: 14px;
}

.profile_sale_add_field_input {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  padding: 0 10px;
  margin-top: 5px;
}

.reformulateAmount_error {
  border: 1px solid red;
}

.profile_sale_add_field_input_error {
  border: 1px solid red;
}
