@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");
.user-profile {
  .header-cover {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -40px;
    z-index: 9;
  }
  .profile-picture {
    border-radius: 50%;
    border: 4px solid $white;
  }
  .profile-nav {
    justify-content: center;
  }
}

.timeline {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;

  .timeline-item {
    position: relative;
    width: 50%;
    display: inline-block;

    &:nth-child(even) {
      padding: 0 3rem 3rem 0;
      .timeline-badge {
        left: calc(100% - 24px);
      }
    }
    &:nth-child(odd) {
      float: right;
      padding: 0 0 3rem 3rem;
      margin-top: 6rem;
      .timeline-badge {
        right: calc(100% - 24px);
      }
    }
    .timeline-badge {
      position: absolute;
      width: 48px;
      height: 48px;
    }
    .badge-icon {
      display: inline-block;
      text-align: center;
      font-size: 22px;
      border-radius: 50%;
      height: 100%;
      width: 100%;
      line-height: 48px;
    }
    .badge-img {
      display: inline-block;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
  }
  .timeline-group {
    position: relative;
    z-index: 99;
    padding: 0 0 2rem 0;
  }
  .timeline-line {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    background: $gray-300;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.active-tab {
  color: #5f3994;
  font-size: 16px;
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  margin-right: 20px;
  font-weight: 700;
  border: 0;
  border-bottom: 2px solid #5f3994;
  padding-left: 0;
  padding-right: 0;
}

.inactive-tab {
  color: #8792a2;
  font-size: 16px;
  background-color: transparent;
  border: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-right: 20px;
  padding-left: 0;
  padding-right: 0;
}

.card-container {
  background-color: white;
  padding: 10px 20px;
  box-shadow: 1px 1px 5px 0px rgba(69, 68, 68, 0.15);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(69, 68, 68, 0.15);
  -moz-box-shadow: 1px 1px 5px 0px rgba(69, 68, 68, 0.15);
  border-radius: 10px;
  height: 100%;
  min-height: 270px;
  margin-bottom: 20px;
}

.current-selected-plan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.current-selected-plan-inner .name {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #2d3748;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: capitalize;
}

.current-selected-plan-inner .plan-label {
  background-color: #b2f5ea;
  margin-left: 10px;
  padding: 4px 16px;
  color: #234e52;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  border-radius: 9999px;
  margin-bottom: 0;
}

.current-selected-plan-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.current-selected-plan-inner img {
  height: 84px;
  width: 78px;
  margin-right: 10px;
}

.current-selected-plan-price {
  color: #2d3748;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 30px;
  display: flex;
  align-items: center;
}

.current-selected-plan-price span {
  color: #8792a2;
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  text-transform: capitalize;
}

.current-selected-plan-time {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #2d3748;
  margin-bottom: 0;
}

.current-selected-plan-time span {
  font-weight: 400;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.current-plan-desc {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 25.2px;
  margin-bottom: 0;
  margin-top: 0;
  text-align: justify;
  text-justify: inter-word;
}

.current-plan-desc a {
  color: #5f3994;
  text-decoration: underline;
}

.current-plan-action-btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
}

.bg-filled-btn {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #5f3994;
  padding: 8px 16px;
  border-radius: 6px;
  border: 0;
  color: white;
  border: 1px solid #5f3994;
}

.new-card-btn {
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  font-weight: 500;
  border: 0;
  color: #5f3994;
  margin-top: 32px;
  text-align: center;
}

.curr-plan-btn-margin {
  margin-left: 10px;
}

.non-filled-bg-btn {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: white;
  padding: 8px 16px;
  border-radius: 6px;
  border: 0;
  color: #5f3994;
  border: 1px solid #5f3994;
}

.component-title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #2d3748;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: capitalize;
}

.component-subtxt {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #2d3748;
  margin-bottom: 0;
}

.single-card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fcfbfd;
  border: 1px solid #e2e8f0;
  padding: 20px 10px;
  margin-top: 20px;
}

.single-card-container-popup {
  padding: 10px 10px !important;
}

.single-card-container img {
  height: 45px;
  width: 54px;
  margin-right: 10px;
}

.card-number {
  color: #2d3748;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  margin-bottom: 0;
}

.card-expires-on {
  font-weight: 400;
}

.btn-group {
  background-color: #fcfbfd;
  border-radius: 10px;
  padding: 6px;
}

.btn-group-active {
  background-color: #ffffff;
  height: 46px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "Poppins", sans-serif;
  border: 0;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
}

.btn-group-inactive {
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  height: 46px;
  font-size: 16px;
  border-radius: 8px;
  padding-left: 30px;
  padding-right: 30px;
  border: 0;
}

.btn-group-active span,
.btn-group-inactive span {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  background: rgba(95, 57, 148, 0.1);
  color: #5f3994;
  padding: 5px 8px;
  border-radius: 6px;
  margin-left: 10px;
}

.row-spaceBtwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.width-140 {
  width: 140px;
}

.plan-container {
  margin: 0 20px;
  text-align: center;
  box-shadow: 1px 1px 5px 0px rgba(69, 68, 68, 0.15);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(69, 68, 68, 0.15);
  -moz-box-shadow: 1px 1px 5px 0px rgba(69, 68, 68, 0.15);
  padding: 16px 20px;
  border-radius: 8px;
}

.plan-container img {
  height: 84px;
  width: 78px;
}

.plan-container .plan-name {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #2d3748;
  font-weight: 600;
  margin-top: 10px;
}

.plan-amount {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  color: #2d3748;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.plan-amount span {
  background: rgba(95, 57, 148, 0.1);
  color: #5f3994;
  padding: 5px 8px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  margin-left: 10px;
}

.choose-plan-btn {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #5f3994;
  padding: 8px 16px;
  border-radius: 6px;
  color: white;
  border: 0;
  width: 231px;
  margin: 30px 0;
}

.your-current-plan {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: rgba(95, 57, 148, 0.2);
  padding: 8px 16px;
  border: 0;
  border-radius: 6px;
  color: rgba(95, 57, 148, 0.7);
  width: 231px;
  margin: 30px 0;
}

.advantages {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #2d3748;
  font-weight: 600;
  margin-bottom: 0;
}

.advantage {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #2d3748;
  font-weight: 400;
  margin: 5px 0;
}

.tick {
  width: 12px !important;
  height: 10.53px !important;
  margin-right: 10px;
}

.all-plans-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 0;
}

.current-plan-container {
  padding-left: 0;
  padding-right: 10px;
  margin-bottom: 20px;
}

.my-cards-container {
  padding-right: 0;
  padding-left: 10px;
  margin-bottom: 20px;
}

.all-plans,
.history {
  padding: 0;
}

.subscription-history-container {
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.table-data {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #2d3748;
  font-weight: 400;
  vertical-align: middle !important;
  border-top: 0 !important;
}

.transaction-status {
  color: #234e52;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding: 4px 16px;
  border-radius: 19px;
  background-color: #b2f5ea;
  margin-bottom: 0;
  width: fit-content;
}

.transaction-rejected {
  color: #234e52;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding: 4px 16px;
  border-radius: 19px;
  background-color: #ffb1b1;
  margin-bottom: 0;
  width: fit-content;
}

.table-data .plan {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  background: rgba(95, 57, 148, 0.1);
  color: #5f3994;
  padding: 4px 16px;
  border-radius: 19px;
  width: fit-content;
  margin: 0;
}

.component-title-margin {
  margin-bottom: 30px;
}

.border-0 {
  border: 0;
}

.suspended-modal .modal-content {
  border-radius: 20px;
}

.payemntStripe-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.go-back img {
  height: 16px;
  margin-right: 10px;
}

.go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #8792a2;
  background: transparent;
  border: 0;
  margin-bottom: 40px;
}

.payment-stripe-container {
  padding: 40px 20px;
  background-color: white;
  margin: 0 -12px 40px;
  box-shadow: 0px 4px 25px 0px #00000014;
  width: 100%;
  max-width: 1036px;
}

.payment-form-container {
  max-width: 421px;
  width: 100%;
}

.payment-form-container-popup {
  margin-left: auto;
  margin-right: auto;
}

.selected-plan-snippet {
  width: 100%;
  max-width: 477px;
}

.selected-plan-snippet .plan-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 13px 0;
}

.selected-plan-snippet .plan-total p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  color: #1a1f36;
}

.selected-plan-snippet .taxes-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 13px 0;
  border-top: 1px solid rgba(60, 66, 87, 0.12);
  border-bottom: 1px solid rgba(60, 66, 87, 0.12);
}

.selected-plan-snippet .taxes-info p {
  color: #697386;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
}

.selected-plan-name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
}

.selected-plan-name-container div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selected-plan-name-container div img {
  height: 75px;
  margin-right: 14px;
}

.selected-plan-name-container div p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  color: #2d3748;
}

.selected-plan-name-container p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 0;
  color: #2d3748;
}

.apple-pay-btn {
  background-color: black;
  height: 48px;
  border-radius: 4px;
  border: 0;
  width: 100%;
}

.apple-pay-btn img {
  width: 58.2px;
}

.payment-form-container .single-card-container {
  margin-top: 10px;
  padding: 18px 10px;
}

.new-card-txt {
  position: relative;
  margin: 32px 0;
}

.new-card-txt-popup {
  margin: 16px auto !important;
}

.new-card-txt div {
  height: 1px;
  background-color: rgba(60, 66, 87, 0.12);
  position: absolute;
  top: 13px;
  width: 100%;
}

.new-card-txt p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #8792a2;
  background-color: white;
  z-index: 2;
  position: relative;
  width: fit-content;
  margin: auto;
  padding: 0 6px;
}

.payment-form-container label {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #697386;
}

.expiry-cvv-container .mm-yy {
  width: 50%;
  border-top: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.payemntStripe-container .card-number {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.payemntStripe-container #mmyy {
  border-bottom-right-radius: 0;
}

.payemntStripe-container #cvv {
  border-bottom-left-radius: 0;
}

.payment-form-container .card-inputs,
.expiry-cvv-container .mm-yy {
  border: 1px solid rgba(60, 66, 87, 0.12);
  border-radius: 8px;
  height: 40px;
  background-color: white;
  padding: 12px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 0;
  width: 100%;
}

.payment-form-container .expiry-cvv-container {
  margin-top: 0;
  position: relative;
}

.payment-form-container .name {
  margin-bottom: 32px;
}

.payment-form-container .name-popup {
  margin-bottom: 8px;
}

.name-input-container {
  position: relative;
}

.name-input-container img {
  position: absolute;
  right: 12px;
  width: 119.72px;
  top: 12px;
}

.expiry-cvv-container img {
  position: absolute;
  right: 12px;
  width: 23.94px;
  top: 12px;
}

.payer {
  margin-top: 16px;
  background-color: #5f3994;
  color: white;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  border: 0;
}

.suspended-modal-body {
  width: 100%;
  max-width: 433px;
  margin: 39px auto;
}

.white-input-container input {
  border-radius: 8px;
  height: 40px;
  color: #000000;
  background-color: white;
  padding: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid rgba(60, 66, 87, 0.12);
  width: 100%;
}

.white-input-container label {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin-bottom: 10px;
}

.white-input-container input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #25252d;
}

.white-input-container input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #25252d;
  opacity: 1;
}

.white-input-container input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #25252d;
  opacity: 1;
}

.white-input-container input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #25252d;
}

.white-input-container input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #25252d;
}

.white-input-container input::placeholder {
  /* Most modern browsers support this now. */
  color: #25252d;
}

.suspended-modal-body p {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #2d3748;
  text-align: center;
  margin-bottom: 35px;
}

.suspended-modal-body .norml-txt {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 10px;
  text-align: left;
}

.suspended-modal-body .sub-container {
  padding: 0 28.5px;
}

.suspended-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 29px;
}

.suspended-modal-footer button {
  width: 48%;
}

.current-plan-desc-bold {
  font-weight: 600;
  margin-top: 10px;
}

@media (max-width: 1772px) {
  .current-plan-desc {
    line-height: inherit;
  }
}

@media (max-width: 1355px) {
  .current-plan-desc {
    margin-top: 0;
    font-size: 13px;
  }
}

@media (max-width: 1300px) {
  .current-selected-plan-price {
    font-size: 20px;
  }
  .current-plan-desc {
    margin-top: 6px;
    font-size: 12px;
  }
  .current-selected-plan-price span {
    margin-left: 3px;
    font-size: 10px;
  }
  .current-selected-plan-inner .name {
    font-size: 16px;
  }
  label {
    margin-left: 5px;
    padding: 2px 8px;
    font-size: 10px;
  }
  .current-selected-plan-time {
    font-size: 12px;
  }
  .current-selected-plan-inner img {
    height: 45px;
    width: auto;
    margin-right: 5px;
  }
  .component-title {
    font-size: 16px;
  }
  .component-subtxt {
    font-size: 13px;
  }
  .card-number {
    font-size: 13px;
  }
}

@media (max-width: 1060px) {
  .non-filled-bg-btn,
  .bg-filled-btn {
    font-size: 13px;
    padding: 5px 10px;
  }
  .payemntStripe-container {
    flex-direction: column;
    align-items: center;
  }
  .selected-plan-snippet {
    margin-bottom: 32px;
  }
  .payment-form-container {
    max-width: 477px;
  }
  .apple-pay-btn {
    height: 40px;
  }
  .apple-pay-btn img {
    width: 50px;
  }
  .payment-form-container .single-card-container {
    padding: 10px 10px;
  }
}

@media (max-width: 992px) {
  .current-plan-container {
    padding-left: inherit;
    padding-right: inherit;
  }
  .my-cards-container {
    padding-right: initial;
    padding-left: initial;
  }
  .card-container {
    min-height: auto;
    padding: 20px;
  }
}

@media (max-width: 900px) {
  .plan-amount {
    font-size: 26px;
  }
  .plan-container img {
    height: 75px;
    width: auto;
  }
  .plan-container .plan-name {
    font-size: 16px;
  }
  .advantage {
    font-size: 13px;
  }
  .all-plans-container {
    flex-wrap: wrap;
    margin: 40px 0 0;
  }
  .btn-group-active,
  .btn-group-inactive {
    height: 38px;
  }
  .btn-group {
    padding: 4px;
  }
  .plan-container {
    margin: 0 30px 30px;
  }
}

@media (max-width: 685px) {
  .all-plans-tab-view {
    flex-direction: column;
    align-items: flex-start;
  }
  .btn-group {
    align-self: center;
  }
  .table-data {
    font-size: 12px;
  }
  .table-data .plan {
    font-size: 12px;
  }
  .curr-plan-btn-margin {
    margin-left: 10px;
  }
  .payment-stripe-container {
    margin: 0 0 40px;
  }
}

@media (max-width: 480px) {
  .curr-plan-btn-margin {
    margin-left: 0px;
    margin-top: 10px;
  }
  .current-selected-plan,
  .current-plan-action-btns {
    flex-direction: column;
    align-items: center;
  }
  .current-selected-plan-inner {
    flex-direction: column;
    margin-bottom: 6px;
  }
  .current-selected-plan-inner img {
    height: 75px;
    margin-bottom: 6px;
  }
  .inactive-tab,
  .active-tab {
    margin-right: 10px;
  }
  .current-selected-plan-inner .plan-label {
    margin: 6px 0;
  }
  .mob-min-width-250 {
    min-width: 250px;
  }
  .single-card-container {
    flex-direction: column;
  }
  .single-card-container .bg-filled-btn {
    min-width: 250px;
    margin-top: 16px;
  }
  .single-card-container img {
    height: 33px;
    width: 42px;
    margin-right: 8px;
  }
  .selected-plan-name-container {
    flex-direction: column;
  }
  .selected-plan-name-container div {
    flex-direction: column;
  }
  .selected-plan-name-container div img {
    margin-right: 0;
  }
  .plan-container {
    margin: 0 0 30px;
    width: 100%;
  }
}

@media (max-width: 400px) {
  .name-input-container img,
  .expiry-cvv-container img {
    display: none;
  }
}

@media (max-width: 385px) {
  .mobile-colmn {
    flex-direction: column !important;
  }
  .current-selected-plan-inner .plan-label {
    margin-left: 0;
  }
  .suspended-modal-body .sub-container {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .user-profile {
    .header-cover {
      height: 200px;
    }
  }
  .timeline {
    .timeline-item {
      width: 100%;
      padding: 4rem 0 3rem !important;
      &:nth-child(odd) {
        margin-top: 1rem;
      }
      .timeline-badge {
        left: 0 !important;
        right: 0 !important;
        top: -16px;
        margin: auto;
      }
    }
    .timeline-group {
      padding: 0 0 3rem;
    }
  }
}
