@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-poppins {
  font-family: "Poppins", sans-serif !important;
}

@media (min-width: 2000px) {
  .container-xxl {
    max-width: 1140px;
  }
}

.bg-primary-light {
  background-color: #9b7db8;
}

.bg-color-1 {
  background: white;
  background: linear-gradient(
    25deg,
    #d7d7d7 0%,
    #c8c8c8 28%,
    white 62%,
    white 100%
  );
}

.icon-hover * {
  transition: all 0.3s ease;
}

.icon-hover:hover img {
  transform: scale(1.05);
}

.address-input .form-control::placeholder {
  color: rgb(138, 138, 138);
}

.text-underline {
  text-decoration: underline;
}

.blog-bg {
  background-size: cover;
  background-position: center;
  height: 400px;
  @media (max-width: 800px) {
    height: 300px;
  }

  @media (max-width: 500px) {
    height: 170px;
  }
}

.blog-box {
  margin-top: -265px;
  @media (max-width: 800px) {
    margin-top: -180px;
  }

  @media (max-width: 500px) {
    margin-top: -50px;
  }
}

.blog-bg-color {
  background-color: #ffffff4f;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border: 0;
  span {
    font-size: 20px;
    color: #d5d1d1;
  }
  &:hover,
  &:focus,
  .active {
    background-color: unset;
    box-shadow: unset !important;

    span {
      color: #663399;
    }
  }
}

.page-link {
  border-radius: 50px;
}

.faq-tabs {
  .nav-tabs {
    border-bottom: 0;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    .nav-link,
    .nav-link:hover {
      border: unset;
    }

    .nav-link.active {
      color: #663399;
      background-color: unset;
      border: unset;
      font-weight: 600;
    }
  }
}

.container-VMZ {
  h3 {
    display: none;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-5-24 14:1:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1s
    both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
}

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-5-24 13:58:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.fade-in-left {
  -webkit-animation: fade-in-left 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-5-24 13:55:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.slide-in-elliptic-left-fwd {
  -webkit-animation: slide-in-elliptic-left-fwd 1s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
  animation: slide-in-elliptic-left-fwd 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    1s both;
}

/**
 * ----------------------------------------
 * @animation slide-in-elliptic-left-fwd 
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-left-fwd {
  0% {
    -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
    transform: translateX(-800px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
    transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
    transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 1800px 50%;
    transform-origin: 1800px 50%;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-left-fwd {
  0% {
    -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
    transform: translateX(-800px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
    transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
    transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 1800px 50%;
    transform-origin: 1800px 50%;
    opacity: 1;
  }
}

.p-home {
  padding-top: 75px;
}

.p-sec {
  padding-top: 50px;
  padding-bottom: 50px;
}

.bg-container-2 {
  background-color: transparent;
  background-position: bottom;
  background-size: cover;

  .full-width {
    width: 100%;
  }

  .fillter {
    backdrop-filter: blur(2.5px);
    padding-bottom: 140px;
  }

  .main-content-wrap {
    position: relative;
    z-index: 3;
    background-color: transparent;

    .form-control {
      background-position: 2% center !important;
    }
  }
}

.sm-menu {
  position: absolute;
  width: 265px;
  background-color: #f2f1f1 !important;
  border-radius: 6px;
  box-shadow: 0 0 5px 0px #9828b4;
  padding: 20px;
  top: 75%;
  right: 0;

  > div {
    display: flex;
    flex-direction: column;
  }
}

.steps-home {
  .pac-target-input {
    background-position: 3% center;
  }
}

.modal-pos {
  left: 25.5% !important;
  top: 5% !important;
  @media screen and (max-width: 992px) {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    position: absolute !important;
    width: 70% !important;
  }
}

.white-space-nowrap {
  white-space: nowrap;
}

.breadcrumb ul {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 8px !important;
  padding-bottom: 8px !important;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}

.pos-3 {
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  min-height: 100vh;
  min-width: 100vw;
}

.layout-sidebar-large
  .sidebar-left
  .navigation-left
  .nav-item
  .nav-item-hold.active,
.layout-sidebar-large
  .sidebar-left
  .navigation-left
  .nav-item
  .nav-item-hold:hover {
  color: #663399;
}

.layout-sidebar-large
  .sidebar-left
  .navigation-left
  .nav-item
  .nav-item-hold.active
  .nav-text {
  font-weight: 800;
}

.nav-pills-2 .nav-link {
  border-radius: 0.25rem;
  border: 1px solid #663399;
  margin-bottom: 1.5rem;
  position: relative;
}

.nav-pills-2 .nav-link.active,
.nav-pills .show > .nav-link {
  color: #663399;
  background-color: transparent;
}

.sidebar-c .nav-link {
  color: #000;
}

.sidebar-c .nav-link:hover,
.sidebar-c .nav-link.active {
  color: #663399;
}

.sidebar-c .nav-item:hover > .nav-link::after,
.nav-link:active::after,
.nav-link.active::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: -1px;
  bottom: -1px;
  border-color: transparent transparent #639;
  border-style: solid;
  border-width: 0 0 25px 25px;
}

.remove-arrow-nav .nav-link:active::after,
.remove-arrow-nav .nav-link.active::after {
  display: none !important;
}

.main-height {
  height: 87%;
}

.gy-2 {
  row-gap: 10px;
}

.gy-3 {
  row-gap: 20px;
}

.border-2 {
  border: 2px dotted #b5b5b5;
}

.min-height-26rem {
  min-height: 26rem;
}

.chart-small {
  width: 180px;

  .apexcharts-datalabel-label {
    font-size: 12px !important;
    transform: translateY(3px);
    -webkit-transform: translateY(3px);
    -moz-transform: translateY(3px);
    -ms-transform: translateY(3px);
    -o-transform: translateY(3px);
  }

  .apexcharts-datalabel-value {
    font-size: 25px !important;
    // transform: translateY(-13px);
  }
}

// ==

.table th,
.table td {
  white-space: nowrap;
}

// == live call room

.room {
  .participant {
    position: relative;

    video {
      height: 100%;
      width: 100%;
      border-radius: 0.5rem;
      box-shadow: 0 0 5px 1px #663399b8;
    }

    .user-name {
      position: absolute;
      text-transform: capitalize;
      bottom: 1.4rem;
      left: 1rem;
      border-radius: 50%;
      background-color: #663399;
      color: #fff;
      height: 4.2rem;
      width: 4.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}

a.disabled {
  color: #47404f96 !important;
  pointer-events: none;
  cursor: not-allowed;
  span {
    font-weight: 400 !important;
  }
}

.navigation-left {
  .nav-item-disabled {
    cursor: not-allowed !important;
  }
}

// .react-select-3 div {
//   min-width: 100px;
// }

.web-link {
  &.active {
    background-color: #663399;
    color: #fff !important;
    border-radius: 0.3rem;
    padding: 8px 20px;
  }
}

.w-110px {
  width: 110px;
}

.w-56px {
  width: 3.5rem;
}

.w-32px {
  width: 2rem;
}

.checkbox .checkmark,
.radio .checkmark {
  height: 16px !important;
  width: 16px !important;
}

.badge-new {
  transform: translate(-80%, -90%);
}

// *************************** NewLeads ***************************

.newleads_opportunities > .nav-link {
  color: #1a202c !important;
  padding: 12px 16px !important;
}

.newleads_opportunities > .active {
  color: #5f3994 !important;
  background-color: rgba(95, 57, 148, 0.1) !important;
  border-radius: 10px !important;
}

.newleads_filter {
  background-color: #5f3994;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  > h4 {
    color: white;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }
}

.newleads_filter-search {
  .css-t3ipsp-control {
    width: 100%;
    height: 48px;
    border-radius: 6px;
    border: 1px solid #e2e8f0;
  }

  .css-13cymwt-control {
    width: 100%;
    height: 48px;
    border-radius: 6px;
    border: 1px solid #e2e8f0;
  }
}

.newleads_filter-select {
  .css-t3ipsp-control {
    width: 100%;
    height: 48px;
    border-radius: 6px;
    border: 1px solid #e2e8f0;
  }

  .css-13cymwt-control {
    width: 100%;
    height: 48px;
    border-radius: 6px;
    border: 1px solid #e2e8f0;
  }

  .css-16xfy0z-control {
    width: 100%;
    height: 48px;
    border-radius: 6px;
    border: 1px solid #e2e8f0;
  }
}

.newLeads_prospects_list {
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newLeads_prospects_list_head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 30px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 100px;
    flex-shrink: 0;
  }

  .newLeads_prospects_list_head_actiontype {
    width: 120px;
  }
}

.newLeads_prospects_list_head_icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  margin-left: 4px;
  flex-shrink: 0;

  img {
    cursor: pointer;
    height: 10px;
    width: 10px;
  }

  img + img {
    margin-top: 3px;
  }
}

.newLeads_prospects_list_item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  cursor: pointer;

  > div {
    width: 100px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  :last-child {
    width: 120px;
  }
}

.newLeads_prospects_list_item_profils {
  background-color: #e2e8f0;
  padding: 4px 16px;
  border-radius: 10px;
  width: 100px;
}

.newLeads_prospects_list_item_status {
  background-color: #e2e8f0;
  padding: 4px 16px;
  border-radius: 10px;
}

.newLeads_prospects_list_item_remiseSurMarche_oui {
  color: #319795;
  font-weight: 700;
}

.newLeads_prospects_list_item_remiseSurMarche_non {
  color: #e53e3e;
  font-weight: 700;
}

.newLeads_prospects_list_item_valeurVMZ {
  color: #5f3994;
  font-weight: 600;
}

.newLeads_prospects_list_item_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 10px 5px;
}

.newLeads_prospects_list_item_active {
  border: 1px solid #e2e8f0;
  border-radius: 10px;
}

.newLeads_card_tab_title > .nav-link {
  color: black !important;
  padding: 12px 16px !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #1a202c !important;
  font-weight: 500 !important;
}

.newLeads_card_tab_title > .active {
  border-bottom: 2px solid #5f3994 !important;
  background-color: white !important;
  color: #5f3994 !important;
}

.newLeads_propertyInfo {
  padding: 5px;
  font-size: 14px;

  .newLeads_propertyInfo_first {
    padding: 20px 10px;
    border: 1px solid #e2e8f0;
    border-radius: 10px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  .newLeads_propertyInfo_second {
    display: flex;

    .newLeads_propertyInfo_second_child {
      width: 100%;
      padding: 20px 10px;
      border: 1px solid #e2e8f0;
      border-radius: 10px;
      margin-top: 10px;
      margin-bottom: 10px;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        :last-child {
          font-weight: 600;
        }
      }
    }

    .newLeads_propertyInfo_second_child + .newLeads_propertyInfo_second_child {
      margin-left: 10px;
    }
  }

  .newLeads_propertyInfo_third {
    padding: 20px 10px;
    border: 1px solid #e2e8f0;
    border-radius: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    :last-child {
      font-weight: 600;
    }
  }
}

.newLeads_prospects_list_item_EstimationHaute {
  background-color: #b2f5ea;
  padding: 2px 8px;
  border-radius: 6px;
  color: #234e52;
  margin-right: 5px;
  font-weight: 500;
}

.newLeads_project {
  padding: 5px;
  font-size: 14px;

  .newLeads_project_first {
    padding: 20px 10px;
    border: 1px solid #e2e8f0;
    border-radius: 10px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      :last-child {
        font-weight: 600;
      }
    }
  }
}
.newLeads_contact {
  padding: 5px;
  font-size: 14px;

  .newLeads_contact_first {
    padding: 20px 10px;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    margin-bottom: 10px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      :last-child {
        font-weight: 600;
      }
    }
  }

  .newLeads_contact_contact_notaccepted {
    padding: 20px 10px;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

.newLeads_notes {
  padding: 5px;
  font-size: 14px;

  .newLeads_notes_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    :first-child {
      font-weight: 700;
      text-transform: uppercase;
    }

    :last-child {
      border: 1px solid #e2e8f0;
      border-radius: 6px;
      font-weight: 500;
      padding: 8px 16px;
      cursor: pointer;
    }
  }

  .newLeads_notes_list {
    padding: 20px 10px;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    margin-top: 10px;
  }

  .newLeads_notes_list_item_date {
    font-weight: 600;
    margin-top: 10px;
  }

  .newLeads_notes_list_item_text {
    margin-top: 5px;
  }
}

.newLeads_pagination_leadmore {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 2rem;
  // margin-bottom: 2rem;
}

.newLeads_pagination_container {
  .pagination {
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: center;
  }
}

.newLeads_note {
  font-size: 14px;
  margin-bottom: 8px;
  :first-child {
    font-weight: 600;
    margin-bottom: 8px;
  }
}

.poppins_font {
  font-family: "Poppins", sans-serif;
}

.newLeads_prospects_list_item_actionType {
  padding: 8px 16px;
  color: #5f3994;
  background-color: white;
  font-weight: 500;
  border: 1px solid #5f3994;
  cursor: pointer;
  border-radius: 6px;
  min-width: 120px;
  img {
    margin-right: 8px;
  }
}

.newLeads_prospects_list_item_actionType_disabled {
  cursor: not-allowed;
}

.newLeads_prospects_list_item_actionType_selected {
  color: #6d5c85;
  border: 1px solid #6d5c85;
  cursor: not-allowed;
}

.newleads_multiselect_input {
  width: 100%;
  height: 48px;
  border-radius: 6px;
  padding-left: 40px;
  border: 1px solid #e2e8f0;
}

.newleads_multiselect {
  position: relative;

  img {
    position: absolute;
    left: 10px;
    top: 14px;
  }
}

.newleads_multiselect_dropdown {
  position: absolute;
  top: 50px;
  width: 100%;
  background-color: white;
  padding: 8px 4px;
  max-height: 250px;
  overflow-y: auto;
  z-index: 100;

  @media (max-width: 992px) {
    width: 50%;
  }
}

.newleads_multiselect_sidedropdown {
  position: absolute;
  top: 50px;
  left: 100%;
  width: 200px;
  background-color: white;
  padding: 8px 4px;
  max-height: 250px;
  overflow-y: auto;
  z-index: 100;

  @media (max-width: 992px) {
    width: 50%;
    left: 50%;
  }
}

.newleads_multiselect_dropdown_option {
  padding: 5px 10px;
  cursor: pointer;
}

.newleads_multiselect_dropdown_option:hover {
  background-color: rgb(222, 235, 255);
}

.newleads_multiselect_dropdown_option_selected {
  padding: 5px 10px;
  cursor: pointer;
  background-color: rgb(76, 154, 255);
}

.newleads_multiselect_sidedropdown_option label {
  display: flex;
  align-items: center;
}

.newleads_multiselect_sidedropdown_option input {
  margin-right: 5px;
}

.load_more {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 5px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  background-color: #5f3994;
  cursor: pointer;
}

.load_more_disabled {
  cursor: not-allowed !important;
  background-color: #ccc !important;
}
.refresh_button {
  position: fixed;
  bottom: 10vh;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 15px;
  border-radius: 6px;
  border: none;
  color: white;
  background-color: #5f3994;
  cursor: pointer;
  z-index: 99;
  opacity: 0.8;
}

.autocomplete_predictions {
  position: absolute;
  width: 69%;
  top: 65px;
  left: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 10;
  align-content: center;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    width: 90%;
  }
}

.autocomplete_prediction {
  background-color: white;
  background-image: url(/assets/icons/location.png);
  background-position: 5px;
  background-size: 15px;
  background-repeat: no-repeat;
  cursor: pointer;
  padding: 2px 2px;
  padding-left: 30px;
  border-bottom: 1px solid #ccc;
  z-index: 10;
  text-align: left;
}

.autocomplete_prediction:hover {
  background-color: #eee;
}

.text-violet-2 {
  color: #5f3994;
}

.text-black-2 {
  color: #25252d;
}

.bg-violet-o-5 {
  background-color: #5f39940a;
}

.bg-violet-light {
  background-color: #5f39941a;
}

.gap-2 {
  gap: 0.7rem;
}

.gap-1 {
  gap: 1rem;
}

.text-muted-light {
  color: #8792a2;
}
