.custom_tr {
  border-spacing: 10px;
}

.centred_td {
  vertical-align: middle !important;
  // min-height: 15% !important;
}

table {
  table-layout: fixed;
}
td {
  width: 33%;
}
